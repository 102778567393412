/*
 |--------------------------------------------------------------------------
 | Table
 |--------------------------------------------------------------------------
 */

.table {
    overflow-x: auto;
}

.b-table .table {
    th,
    tr {
        background-color: #fff;
    }
    @include desktop() {
        border-color: $grey-lighter;
    }
}

.table.has-mobile-cards tr {
    background-color: #fff !important;
    margin: 0 !important;
    box-shadow: none;
}

.table.is-wide {
    @include desktop() {
        th,
        td {
            padding: 1rem 0.75rem;
        }
    }
}
