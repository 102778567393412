.box {
    position: relative;
}

.box > .delete {
    right: 0.5rem;
    position: absolute;
    top: 0.5rem;
}

.box {
    &.is-large {
        @include tablet() {
            padding: 3rem;
        }
        padding: 1.5rem;
    }
}
