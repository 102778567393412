.login {
    display: flex;

    &.is-fullheight {
        .login-body {
            height: 100vh;
        }
    }

    .subtitle {
        font-weight: 400;
        color: $grey;
    }

    .label {
        font-weight: 400;
        color: $grey;
    }

    .button {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .login-left {
        min-width: 560px;
        height: 100vh;
        overflow-y: auto;

        @include mobile() {
            width: 100%;
            min-width: auto;
        }
    }

    .login-right {
        height: 100vh;
        flex: 1 0 0;
        background-position: center;
        background-size: cover;

        @include mobile() {
            display: none;
        }
    }

    .login-container {
        position: relative;
        max-width: 400px;
        margin: 0 auto;
        min-height: 100%;
        display: flex;
        align-items: center;
    }

    .login-body {
        padding: 0 1rem;
        padding-top: 6rem;
        padding-bottom: 3rem;
        width: 100%;
    }

    .login-header {
        position: absolute;
        left: 1rem;
        top: 1rem;
        width: 100%;
        .login-brand {
            height: 40px;
        }
    }
}
