.columns.is-same-height {
    .column {
        flex-direction: column;
    }
}

.columns.is-2 {
    .column {
        padding: 0.5rem;
    }
}
