@import "~bulma/sass/utilities/initial-variables";

/*
 |--------------------------------------------------------------------------
 | Responsive
 |--------------------------------------------------------------------------
*/

$tablet: 640px;
$desktop: 1024px;
$widescreen: 1280px;
$fullhd: 1536px;

/*
 |--------------------------------------------------------------------------
 | Font Family
 |--------------------------------------------------------------------------
*/

$family-sans-serif: "Inter var", system-ui, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue",
    "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

/*
 |--------------------------------------------------------------------------
 | Base colors
 |--------------------------------------------------------------------------
*/

$red: #dc2626;
$yellow: #fbbf24;
$blue: #3b82f6;
$green: #059669;
$cyan: #4f46e5;

$black: hsl(216, 100%, 4%);
$black-bis: hsl(221, 39%, 11%);
$black-ter: hsl(215, 28%, 12%);

$grey-darker: hsl(215, 28%, 18%);
$grey-dark: hsl(215, 12%, 30%);
$grey: hsl(219, 10%, 48%);
$grey-light: hsl(218, 11%, 75%);
$grey-lighter: hsl(216, 12%, 84%);

$white-darker: hsl(216, 12%, 84%);
$white-dark: hsl(220, 13%, 91%);
$white-ter: hsl(220, 14%, 96%);
$white-bis: hsl(210, 20%, 98%);
$white: hsl(210, 43%, 100%);

/*
 |--------------------------------------------------------------------------
 | Brand colors
 |--------------------------------------------------------------------------
*/

$primary: $blue;
$info: $blue;
$danger: $red;
$warning: $yellow;
$success: $green;

$light: $white-ter;
$dark: $grey-darker;

/*
 |--------------------------------------------------------------------------
 | Typography
 |--------------------------------------------------------------------------
*/

$body-line-height: 1.25;

$size-1: 2.8rem;
$size-2: 2.4rem;
$size-3: 1.9rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.875rem;

$text-color: $black;
$text-color-soft: $grey;
$side-navbar-width: 300px;

$size-small: $size-7;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-extrabold: 800;

/*
 |--------------------------------------------------------------------------
 | Control
 |--------------------------------------------------------------------------
 */

$control-height: 2.5em;
$control-line-height: 1.25;

/*
 |--------------------------------------------------------------------------
 | Form
 |--------------------------------------------------------------------------
 */

$input-shadow: none;
$input-arrow: $grey-light;

/*
 |--------------------------------------------------------------------------
 | Box shadow
 |--------------------------------------------------------------------------
 */

$box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

/*
 |--------------------------------------------------------------------------
 | Button
 |--------------------------------------------------------------------------
*/

$button-border-width: 1px;
$button-padding-vertical: calc(0.4em - #{$button-border-width});
$button-padding-horizontal: 1em;

/*
 |--------------------------------------------------------------------------
 | Export
 |--------------------------------------------------------------------------
*/

:export {
    tablet: $tablet;
    desktop: $desktop;
    widescreen: $widescreen;
    fullhd: $fullhd;
}
