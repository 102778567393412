/*
 |--------------------------------------------------------------------------
 | Tabs
 |--------------------------------------------------------------------------
*/

.b-tabs.is-paddingless {
    .tab-content {
        padding-left: 0;
        padding-right: 0;
    }
}
