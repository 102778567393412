/*
 |--------------------------------------------------------------------------
 | Navigation
 |--------------------------------------------------------------------------
 */

.navbar-item {
    .icon.is-left {
        margin-right: 12px;
    }
    .icon.is-right {
        margin-left: 12px;
    }
}

/*
 |--------------------------------------------------------------------------
 | Dropdown Navigation
 |--------------------------------------------------------------------------
 */

.navbar .navbar-dropdown.is-boxed .navbar-item {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 100%;
    color: $black;
    font-size: 1em;

    &:hover {
        background-color: whitesmoke !important;
    }

    .icon {
        font-size: 1.5em;
        color: $grey-light;
    }
}
