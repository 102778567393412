// Modal

.modal {
    .modal-content {
        padding: 0.5rem;
        padding-top: 5rem;
        max-height: 100%;
    }
    .modal-card {
        max-height: 100%;
        width: 100%;
    }

    .modal-card-head {
        background-color: #fff;
    }

    .modal-card-foot {
        background-color: #fff;
    }
}

// Sheet

.modal.is-sheet {
    align-items: flex-end;
    .modal-content {
        padding: 0;
        margin: 0;
        height: 100%;
        padding-left: 5rem;

        @include mobile() {
            padding-top: 5rem;
            padding-left: 0;
        }
    }
    .modal-sheet-content {
        height: 100%;
        margin: 0;
        padding: 1rem;
        background-color: #fff;
    }
    .modal-close {
        left: 20px;
        right: auto;
    }
}

// Dialog

.dialog.modal {
    padding: 0 0.5rem;

    .modal-card-head {
        padding-bottom: 0;
        border: none;
    }

    .modal-card-foot {
        padding-top: 0;
        border: none;
    }

    .modal-card-title {
        flex-shrink: initial;
    }
}
