.tabs {
    li.is-disabled a {
        color: $grey-light;
        cursor: not-allowed;

        &:hover {
            border-bottom-color: transparent;
        }
    }
}
