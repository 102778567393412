/*
 |--------------------------------------------------------------------------
 | Fade
 |--------------------------------------------------------------------------
*/

.fade-enter-active,
.fade-leave-active {
    @include transition(0.4s cubic-bezier(0.23, 1, 0.32, 1));
}

.fade-enter,
.fade-leave-active {
    opacity: 0 !important;
}

/*
 |--------------------------------------------------------------------------
 | Slide Up
 |--------------------------------------------------------------------------
 */

.slideUp-enter-active,
.slideUp-leave-active {
    @include transition(all 0.3s cubic-bezier(0.23, 1, 0.32, 1));
}

.slideUp-enter,
.slideUp-leave-active {
    @include transform(translateY(60px));
    opacity: 0;
}

/*
 |--------------------------------------------------------------------------
 | Slide Right
 |--------------------------------------------------------------------------
 */

.slideRight-enter-active,
.slideRight-leave-active {
    @include transition(all 0.3s cubic-bezier(0.23, 1, 0.32, 1));
}

.slideRight-enter,
.slideRight-leave-active {
    @include transform(translateX(-100%));
}

/*
 |--------------------------------------------------------------------------
 | Slide Right
 |--------------------------------------------------------------------------
 */

.slideLeft-enter-active,
.slideLeft-leave-active {
    @include transition(all 0.3s cubic-bezier(0.23, 1, 0.32, 1));
}

.slideLeft-enter,
.slideLeft-leave-active {
    @include transform(translateX(100%));
}

/*
 |--------------------------------------------------------------------------
 | Slide Right
 |--------------------------------------------------------------------------
 */

.sheetLeft-enter,
.sheetLeft-enter-active,
.sheetLeft-leave-active {
    @include transition(opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1));
    .animation-content {
        @include transition(all 0.3s cubic-bezier(0.23, 1, 0.32, 1));
    }
}

.sheetLeft-enter,
.sheetLeft-leave-active {
    opacity: 0 !important;
    .animation-content {
        @include transform(translateX(100%));
    }
}

/*
 |--------------------------------------------------------------------------
 | Slide Scale
 |--------------------------------------------------------------------------
 */

.scale-enter-active,
.scale-leave-active {
    @include transition(all 0.3s cubic-bezier(0.23, 1, 0.32, 1));
}

.scale-enter,
.scale-leave-active {
    @include transform(scale(1.2));
    opacity: 0;
}

/*
 |--------------------------------------------------------------------------
 | Slide Left Right
 |--------------------------------------------------------------------------
 */

.slideLeftRight-enter-active,
.slideLeftRight-leave-active {
    @include transition(all 0.4s cubic-bezier(0.23, 1, 0.32, 1));
}

.slideLeftRight-enter {
    @include transform(translateX(-200px));
    opacity: 0;
}

.slideLeftRight-leave-active {
    @include transform(translateX(200px));
    opacity: 0;
}

/*
 |--------------------------------------------------------------------------
 | List
 |--------------------------------------------------------------------------
 */

.list-item {
    display: inline-block;
    margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
    transition: all 0.4s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
