.icon.is-stamp {
    background-color: $grey;
    color: #fff;
    padding: 1.3em;
    border-radius: $radius;

    &.is-primary {
        background-color: $primary;
    }

    &.is-info {
        background-color: $info;
    }

    &.is-warning {
        background-color: $warning;
    }

    &.is-success {
        background-color: $success;
    }

    &.is-danger {
        background-color: $danger;
    }
}

.icon.is-circle {
    background-color: $grey;
    color: #fff;
    padding: 1.25em;
    border-radius: 9999px;

    &.is-primary {
        background-color: $primary;
    }

    &.is-info {
        background-color: $info;
    }

    &.is-warning {
        background-color: $warning;
    }

    &.is-success {
        background-color: $success;
    }

    &.is-danger {
        background-color: $danger;
    }
}
