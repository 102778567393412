/*
|--------------------------------------------------------------------------
| Responsive utils
|--------------------------------------------------------------------------
*/

@include mobile() {
    .title {
        font-size: $size-4;
    }
    .subtitle {
        font-size: $size-6;
    }
    .is-size-1, .is-1 {
        font-size: $size-2 !important;
    }
    .is-size-2, .is-2 {
        font-size: $size-3 !important;
    }
    .is-size-3, .is-3 {
        font-size: $size-4 !important;
    }
    .is-size-4, .is-4 {
        font-size: $size-5 !important;
    }
    .is-size-5, .is-5 {
        font-size: $size-6 !important;
    }
    
}
    