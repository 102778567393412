/*
 |--------------------------------------------------------------------------
 | Buttons
 |--------------------------------------------------------------------------
*/

.buttons.is-fluid-mobile {
    @include mobile() {
        .button {
            flex-grow: 1;
            width: 100%;
        }
    }
}

.buttons.is-fluid-mobile .button:not(:last-child):not(.is-fullwidth) {
    @include mobile() {
        margin-right: 0;
    }
}
