/*
 |--------------------------------------------------------------------------
 | Label
 |--------------------------------------------------------------------------
 */

.label,
label.checkbox {
    font-weight: 500;
    color: $grey-dark;
    font-size: 15px;

    & > a {
        font-size: 0.75rem;
        color: $info !important;
    }

    &:not(:last-child) {
        margin-bottom: 0.4em;
    }
}

label.checkbox {
    font-weight: $weight-normal;
}

.help {
    margin-top: 0.25rem;
    font-weight: $weight-semibold;
}

/*
 |--------------------------------------------------------------------------
 | Select
 |--------------------------------------------------------------------------
 */

.select {
    width: 100%;
    select {
        width: 100%;
    }
}

.input-suffix {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 13px;
    height: 100%;
    border: 1px solid $grey-lighter;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    min-width: 36px;

    &.is-disabled {
        background-color: $white-ter;
        border-color: $white-ter;
        color: $grey-lighter;
    }
}
