/*
|--------------------------------------------------------------------------
| Leading
|--------------------------------------------------------------------------
*/

.leading-none {
    line-height: 1;
}

.leading-normal {
    line-height: 1.5;
}

/*
|--------------------------------------------------------------------------
| Width
|--------------------------------------------------------------------------
*/

.w-full {
    width: 100%;
}

.w-1\/2 {
    width: 50%;
}

.w-1\/3 {
    width: 33.33%;
}

.w-1\/4 {
    width: 25%;
}

/*
|--------------------------------------------------------------------------
| Truncate
|--------------------------------------------------------------------------
*/

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*
|--------------------------------------------------------------------------
| Rounded full
|--------------------------------------------------------------------------
*/

.is-rounded-full {
    border-radius: 99999px;
}

/*
|--------------------------------------------------------------------------
| Hide Cloak
|--------------------------------------------------------------------------
*/

[v-cloak] {
    display: none;
}

/*
|--------------------------------------------------------------------------
| Loading mask
|--------------------------------------------------------------------------
*/

.loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;
    background-color: rgba($white-bis, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    @include transition(0.2s);

    &.is-active {
        visibility: initial;
        opacity: 1;
    }

    &:after {
        animation: spinAround 500ms infinite linear;
        border: 3px solid $primary;
        border-radius: $radius-rounded;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: 2em;
        position: relative;
        width: 2em;
    }
}
