@include mobile() {
    .level.is-compact .level-left + .level-right {
        margin-top: 0.5rem;
    }
}

.level.is-spaced {
    margin: -0.375rem;
    & > * {
        padding: 0.375rem;
    }

    .level-left,
    .level-right {
        margin: -0.375rem;
        & > * {
            padding: 0.375rem;
        }
    }
}
