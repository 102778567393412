.b-table {
    .table-wrapper {
        margin: 0;
        border-bottom: 1px solid $grey-lighter;
    }

    .table-wrapper:last-child {
        border-bottom: 0;
    }

    .level.top {
        padding: 10px;
        margin: 0;
        border-bottom: 1px solid $grey-lighter;

        .level-right {
            margin: 0;
        }
    }

    .table thead th {
        border-width: 0 0 1px;
    }

    .th-wrap {
        font-size: 14px;
    }

    .table-mobile-sort {
        padding: 0 0.75rem;
        padding-top: 0.75rem;

        .button.is-primary {
            background-color: $white-ter;
            color: $grey-darker;
            border: 1px $white-darker solid;
        }
    }
}
