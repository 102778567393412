/*
 |--------------------------------------------------------------------------
 | Border radius
 |--------------------------------------------------------------------------
 */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

/*
 |--------------------------------------------------------------------------
 | Transition
 |--------------------------------------------------------------------------
 */

 @mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

/*
 |--------------------------------------------------------------------------
 | Transform
 |--------------------------------------------------------------------------
 */

 @mixin transform($transformation) {
    -webkit-transform: $transformation;
    -ms-transform: $transformation;
    transform: $transformation;
}

/*
 |--------------------------------------------------------------------------
 | Box Sizing
 |--------------------------------------------------------------------------
 */

@mixin box-sizing($box-sizing) {
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    -ms-box-sizing:     border-box;
    -o-box-sizing:      border-box;
    box-sizing:         border-box;
}

/*
 |--------------------------------------------------------------------------
 | Animation & Keyframes
 |--------------------------------------------------------------------------
 */

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
}