/*
 |--------------------------------------------------------------------------
 | Button
 |--------------------------------------------------------------------------
*/

.button {
    font-weight: $weight-medium;
}

/*
 |--------------------------------------------------------------------------
 | Button Fluid on mobile
 |--------------------------------------------------------------------------
*/

.button.is-fluid-mobile {
    @include mobile() {
        width: 100%;
    }
}

/*
 |--------------------------------------------------------------------------
 | Transparent button
 |--------------------------------------------------------------------------
*/

.button.is-transparent {
    background-color: transparent !important;
    border: none !important;
}

/*
 |--------------------------------------------------------------------------
 | Inline Text button
 |--------------------------------------------------------------------------
*/

.button.is-inline-text {
    height: auto;
    padding: 0;
    margin-bottom: 0;
    font-weight: 400;
    border: none;
    text-decoration: underline;

    &:hover,
    &:focus {
        background: transparent;
    }
}

/*
 |--------------------------------------------------------------------------
 | Underline button
 |--------------------------------------------------------------------------
*/

.button.is-underline {
    display: inline;
    color: $text;
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    height: auto;
    border: none;
    font-weight: $weight-normal;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: $black-ter;
    }
}

/*
 |--------------------------------------------------------------------------
 | Rounded button
 |--------------------------------------------------------------------------
*/

.button.is-rounded {
    padding-left: 1.3em;
    padding-right: 1.3em;
}

/*
 |--------------------------------------------------------------------------
 | Grey button
 |--------------------------------------------------------------------------
 */

.button.is-grey {
    background-color: $white-dark;
    border-color: $white-dark;

    &:hover {
        border-color: $white-dark;
        background-color: $white-darker;
    }
}

/*
 |--------------------------------------------------------------------------
 | Fill button (big button with dashed border)
 |--------------------------------------------------------------------------
 */

.button.is-fill {
    width: 100%;
    border-radius: 4px;
    height: 4.5rem;
    background-color: transparent;
    border: 2px dashed $grey-lighter;
    color: $grey;
    text-align: center;
    font-weight: $weight-bold;
    @include transition(0.3s);

    &:hover {
        background-color: $white;
    }
}
