.message {
    &.is-bordered {
        .message-body {
            border-width: 1px;
        }
    }
}

.message {
    .message-body {
        padding-left: 1rem;
    }

    .icon.is-large {
        height: 1.3rem;
        width: 1.3rem;
        .mdi.mdi-48px:before {
            font-size: 25px;
        }
    }

    &.is-success {
        background-color: #effaf3;
        .message-body {
            color: #257942;
        }
    }
}
